<template>
  <div style="display: none">
    <div id="printNutricionalTabular">
      <PrintContainer tituloReporte="TABLA DE GESTANTES CON ANEMIA">
        <p v-if="provinciaFiltrado" class="text-sm text-gray-500">
          <FilterOutlined />provincia {{ provinciaFiltrado }}
        </p>

        <p v-if="distritoFiltrado" class="text-sm text-gray-500">
          <FilterOutlined />distrito {{ distritoFiltrado }}
        </p>

        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">{{ tituloDeFiltroUpper }}</th>
              <th class="text-center p-3 border border-black">NUMERADOR</th>
              <th class="text-center p-3 border border-black">DENOMINADOR</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(data, index) in arraySelectPrint" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ data.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ data.numerador }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ data.denominador }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ data.porcentaje }} %</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: SIEN actualizado a la fecha: {{ fechaIngreso }}
              </td>
            </tr>
          </PrintFooter>
        </table>
      </PrintContainer>
    </div>
    <!-- ------------------ IMPRIME ESTABLECIMIENTO ------------------ -->
    <div id="printNutricionalTabularEstablecimiento">
      <PrintContainer tituloReporte="TABLA DE NIÑOS CON ANEMIA POR ESTABLECIMIENTO">
        <p v-if="provinciaFiltrado" class="text-sm text-gray-500">
          <FilterOutlined />provincia {{ provinciaFiltrado }}
        </p>

        <p v-if="distritoFiltrado" class="text-sm text-gray-500">
          <FilterOutlined />distrito {{ distritoFiltrado }}
        </p>

        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <!-- <th class="text-center p-3 border border-black">MICRORED</th> -->
              <th class="text-center p-3 border border-black">{{ tituloDeFiltroUpper }}</th>
              <th class="text-center p-3 border border-black">NUMERADOR</th>
              <th class="text-center p-3 border border-black">DENOMINADOR</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(data, index) in arraySelectPrint" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <!-- <td class="border border-gray-900 px-2">
                {{ data.microred }}
              </td> -->
              <td class="border border-gray-900 px-2">
                {{ data.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ data.numerador }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ data.denominador }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ data.porcentaje }} %</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: SIEN actualizado a la fecha: {{ fechaIngreso }}
              </td>
            </tr>
          </PrintFooter>
        </table>
      </PrintContainer>
    </div>
    <!-- ------------------ IMPRIME TODO ------------------ -->
    <div id="printNutricionalGestanteTodo">
      <PrintContainer tituloReporte="TABLA DE GESTANTES CON ANEMIA">
        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">PROVINCIA</th>
              <th class="text-center p-3 border border-black">NUMERADOR</th>
              <th class="text-center p-3 border border-black">DENOMINADOR</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(data, index) in dataToPrint.provincia" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ data.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ data.numerador }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ data.denominador }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ data.porcentaje }} %</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: SIEN actualizado a la fecha: {{ fechaIngreso }}
              </td>
            </tr>
          </PrintFooter>
        </table>

        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">DISTRITO</th>
              <th class="text-center p-3 border border-black">NUMERADOR</th>
              <th class="text-center p-3 border border-black">DENOMINADOR</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(data, index) in dataToPrint.distrito" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ data.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ data.numerador }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ data.denominador }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ data.porcentaje }} %</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: SIEN actualizado a la fecha: {{ fechaIngreso }}
              </td>
            </tr>
          </PrintFooter>
        </table>

        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <!-- <th class="text-center p-3 border border-black">MICRORED</th> -->
              <th class="text-center p-3 border border-black">ESTABLECIMIENTO</th>
              <th class="text-center p-3 border border-black">NUMERADOR</th>
              <th class="text-center p-3 border border-black">DENOMINADOR</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(data, index) in dataToPrint.establecimiento" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <!-- <td class="border border-gray-900 px-2">
                {{ data.microred }}
              </td> -->
              <td class="border border-gray-900 px-2">
                {{ data.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ data.numerador }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ data.denominador }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ data.porcentaje }} %</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: SIEN actualizado a la fecha: {{ fechaIngreso }}
              </td>
            </tr>
          </PrintFooter>
        </table>
      </PrintContainer>
    </div>
  </div>
</template>

<script>
import PrintContainer from "./PrintContainer.vue";
import PrintFooter from "@/components/PrintFooter.vue";
import { FilterOutlined } from "@ant-design/icons-vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    PrintContainer,
    PrintFooter,
    FilterOutlined
  },
  props: {
    arraySelectPrint: {
      type: Object,
      default: null
    },
    tituloDeFiltro: {
      type: String,
      default: ""
    },
    fechaIngreso: {
      type: String,
      default: ""
    },
    dataToPrint: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const provinciaFiltrado = ref("");
    const distritoFiltrado = ref("");
    const store = useStore();

    watch(store.state.pacientereporte, () => {
      provinciaFiltrado.value = store.state.pacientereporte.provincia;
      distritoFiltrado.value = store.state.pacientereporte.distrito;
    });

    return {
      tituloDeFiltroUpper: computed(() => props.tituloDeFiltro?.toUpperCase()),
      provinciaFiltrado,
      distritoFiltrado
    };
  }
};
</script>

<style scoped></style>
