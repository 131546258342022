<template>
  <a-page-header
    @back="() => $router.go(-1)"
    title="TABLA DE GESTANTES CON ANEMIA"
    class="pt-0 px-0"
  >
    <template #extra>
      <div class="flex flex-wrap items-center gap-3">
        <a-breadcrumb>
          <a-breadcrumb-item><router-link to="/menu">Nutricional</router-link></a-breadcrumb-item>
          <a-breadcrumb-item>Tabla de gestantes con anemia</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <a-form class="md:flex w-full gap-4" ref="formRefFilter" layout="horizontal" :model="formState">
      <a-form-item label="PERIODO" class="m-0 p-0 w-48">
        <a-select @select="onSelectYear" v-model:value="yearEvaluacion">
          <a-select-option v-for="y in years" :value="y" :key="y">
            {{ y }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </div>

  <div class="flex flex-col gap-3 lg:flex-row">
    <div class="w-full lg:w-1/3">
      <a-card size="small">
        <template #title>
          <div class="text-center flex justify-between items-center px-3 py-1">
            <p class="font-semibold">DATOS POR PROVINCIA ({{ dataProvincia.length }})</p>
            <div class="flex gap-3">
              <a-tooltip>
                <template #title>Traer de nuevo todos los datos</template>
                <a-button shape="circle" @click="resetAllFetch">
                  <template #icon><ReloadOutlined /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por provincia</template>
                <a-button
                  shape="circle"
                  v-print="printObj"
                  class="text-red-500 hover:text-red-300"
                  @click="filterToPrint('provincia')"
                >
                  <template #icon><FilePdfFilled /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por provincia</template>
                <a-button
                  shape="circle"
                  @click="handleProvincia"
                  class="text-green-500 hover:text-green-300"
                >
                  <template #icon><FileExcelFilled /></template>
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </template>

        <div class="overflow-x-auto w-full">
          <a-table
            :columns="columnsProvincia"
            :data-source="dataProvincia"
            size="small"
            :pagination="false"
            :custom-row="customRow"
            rowKey="nombre"
            :loading="isLoadingProvincia"
            :rowClassName="
              (record, index) =>
                index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
            "
          >
            <template #rangepages="{ record }">
              <span> {{ dataProvincia.indexOf(record) + 1 }} </span>
            </template>

            <template #nombre="{ text: nombre }">
              <a-tooltip>
                <template #title>Filtrar por {{ nombre }}</template>
                <p>{{ nombre }}</p>
              </a-tooltip>
            </template>

            <!-- <template #porcentaje="{ text: porcentaje }">
              <p class="text-center font-bold">{{ porcentaje }} %</p>
            </template> -->
            <template #porcentaje="{ record, text: porcentaje }">
              <div class="flex gap-4 justify-between items-center text-xs">
                <div class="w-2 h-2 rounded-full" :style="{ background: record.porcentajeHex }" />
                <p class="font-bold">{{ porcentaje }} % &nbsp;</p>
                <p class="text-gray-400">
                  {{ record.numerador }} /
                  {{ record.denominador }}
                </p>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>

    <div class="w-full lg:w-1/3">
      <a-card size="small">
        <template #title>
          <div class="text-center flex justify-between items-center px-3 py-1">
            <div class="text-left">
              <p class="font-semibold">DATOS POR DISTRITO ({{ dataDistrito.length }})</p>
              <p v-if="dataToPrint.provinciaFiltrado" class="text-sm text-gray-500">
                <FilterOutlined />provincia {{ dataToPrint.provinciaFiltrado }}
              </p>
            </div>
            <div class="flex gap-3">
              <a-tooltip>
                <template #title>Descargar datos por distrito</template>
                <a-button
                  shape="circle"
                  v-print="printObj"
                  class="text-red-500 hover:text-red-300"
                  @click="filterToPrint('distrito')"
                >
                  <template #icon><FilePdfFilled /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por distrito</template>
                <a-button
                  shape="circle"
                  @click="handleDistrito"
                  class="text-green-500 hover:text-green-300"
                >
                  <template #icon><FileExcelFilled /></template>
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </template>

        <div class="overflow-x-auto w-full">
          <a-table
            :columns="columnsDistrito"
            :data-source="dataDistrito"
            size="small"
            :custom-row="customRow2"
            :pagination="true"
            rowKey="nombre"
            :loading="isLoadingDistrito"
            :rowClassName="
              (record, index) =>
                index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
            "
          >
            <template #rangepages="{ record }">
              <span> {{ dataDistrito.indexOf(record) + 1 }} </span>
            </template>

            <template #nombre="{ text: nombre }">
              <a-tooltip>
                <template #title>Filtrar por {{ nombre }}</template>
                <p>{{ nombre }}</p>
              </a-tooltip>
            </template>

            <!-- <template #porcentaje="{ record }">
              <p class="text-center font-bold">{{ record.porcentaje }} %</p>
            </template> -->
            <template #porcentaje="{ record, text: porcentaje }">
              <div class="flex gap-4 justify-between items-center text-xs">
                <div class="w-2 h-2 rounded-full" :style="{ background: record.porcentajeHex }" />
                <p class="font-bold">{{ porcentaje }} % &nbsp;</p>
                <p class="text-gray-400">
                  {{ record.numerador }} /
                  {{ record.denominador }}
                </p>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>

    <div class="w-full lg:w-1/3">
      <a-card size="small">
        <template #title>
          <div class="text-center flex justify-between items-center px-3 py-1">
            <div class="text-left">
              <p class="font-semibold">
                DATOS POR ESTABLECIMIENTO ({{ dataCentroEstablecimiento.length }})
              </p>
              <p v-if="dataToPrint.distritoFiltrado" class="text-sm text-gray-500">
                <FilterOutlined />distrito {{ dataToPrint.distritoFiltrado }}
              </p>
            </div>
            <div class="flex gap-3">
              <a-tooltip>
                <template #title>Descargar datos por establecimiento</template>
                <a-button
                  shape="circle"
                  v-print="printObj"
                  class="text-red-500 hover:text-red-300"
                  @click="filterToPrint('establecimiento')"
                >
                  <template #icon><FilePdfFilled /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por establecimiento</template>
                <a-button
                  shape="circle"
                  @click="handleEstablecimiento"
                  class="text-green-500 hover:text-green-300"
                >
                  <template #icon><FileExcelFilled /></template>
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </template>
        <div class="overflow-x-auto w-full">
          <a-table
            :columns="columnsEstablecimiento"
            :data-source="dataCentroEstablecimiento"
            size="small"
            :pagination="true"
            rowKey="nombre"
            :loading="isLoadingEstablecimiento"
            :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
          >
            <template #rangepages="{ record }">
              <span> {{ dataCentroEstablecimiento.indexOf(record) + 1 }} </span>
            </template>

            <template #porcentaje="{ record, text: porcentaje }">
              <div class="flex gap-4 justify-between items-center text-xs">
                <div class="w-2 h-2 rounded-full" :style="{ background: record.porcentajeHex }" />
                <p class="font-bold">{{ porcentaje }} % &nbsp;</p>
                <p class="text-gray-400">
                  {{ record.numerador }} /
                  {{ record.denominador }}
                </p>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
  </div>

  <a-alert class="fixed bottom-1 left-1" message="Leyenda" closable>
    <template #description>
      <div>NUM: Número de casos de anemia</div>
      <div>DEN: Número de gestantes evaluadas</div>
    </template>
  </a-alert>

  <PrintNutricionalGestanteTabular
    :arraySelectPrint="arraySelectPrint"
    :dataToPrint="dataToPrint"
    :tituloDeFiltro="tituloDeFiltro"
    :fechaIngreso="fechaIngreso"
  />
</template>

<script>
import { ref, watch, reactive, computed, toRaw, onMounted } from "vue";
import { useStore } from "vuex";
import PacienteReporteApi from "@/api/nutricionalreporte";
import PrintNutricionalGestanteTabular from "@/print/PrintNutricionalGestanteTabular.vue";
import { columnsProvincia, columnsDistrito, columnsEstablecimiento } from "./utilsTabular";
import { calculaPorcentaje, semaforoMapa, generaLeyendaSemaforoPorcentaje } from "@/utils";
import MapaApi from "@/api/mapa";
import UsuariosApi from "@/api/user";
import moment from "moment";
import ApiIndicador from "@/api/indicador";

import {
  FileExcelFilled,
  ReloadOutlined,
  FilePdfFilled,
  FilterOutlined
} from "@ant-design/icons-vue";

export default {
  components: {
    PrintNutricionalGestanteTabular,
    // icons
    FileExcelFilled,
    ReloadOutlined,
    FilePdfFilled,
    FilterOutlined
  },
  setup() {
    const store = useStore();
    const isLoadingProvincia = ref(false);
    const isLoadingDistrito = ref(false);
    const isLoadingEstablecimiento = ref(false);
    const tituloDeFiltro = ref("");
    const fechaIngreso = ref("");
    const anios = ref([]);
    const arraySelectPrint = ref([]);
    const escalaColores = ref([]);

    var anio = moment(new Date()).local().format("YYYY");

    var oldanios = [];
    for (let index = 0; index < 4; index++) {
      oldanios.push(anio - index);
    }

    anios.value = oldanios;

    const years = ref(oldanios);
    const yearEvaluacion = ref(years.value[1]);

    const dataToPrint = reactive({
      provincia: [],
      distrito: [],
      establecimiento: [],
      provinciaFiltrado: "",
      distritoFiltrado: ""
    });

    const coloresSemaforo = reactive({
      POCO_DENOMINADOR: "5",
      PORCENTAJE_BAJO: "10",
      PORCENTAJE_MEDIO: "20",
      PORCENTAJE_ALTO: "30"
    });

    const printObj = reactive({
      id: "printNutricionalTabular",
      popTitle: "SIVINUR | NUTRICIONAL TABULAR"
    });

    const printObjEstablecimiento = reactive({
      id: "printNutricionalTabularEstablecimiento",
      popTitle: "SIVINUR | NUTRICIONAL TABULAR"
    });

    const printObjTodo = reactive({
      id: "printNutricionalGestanteTodo",
      popTitle: "SIVINUR | NUTRICIONAL TABULAR"
    });

    const formState = reactive({
      anio: "2021"
    });

    const dataProvincia = ref([]);
    const dataDistrito = ref([]);
    const dataCentroEstablecimiento = ref([]);
    const { establecimiento } = store.state.user.usuario;

    const state = reactive({
      selectedRowKeys: [],
      rowActiveStatus: []
    });

    const fetchProvincia = () => {
      isLoadingProvincia.value = true;

      PacienteReporteApi.getAllProvinciaGestanteAnemia(yearEvaluacion.value)
        .then(async (response) => {
          // console.log("getAllProvincia", response);
          const dataFormated = response.data.map((d) => {
            const porcentaje = calculaPorcentaje(d.numerador, d.denominador);
            const porcentajeHex = semaforoMapa(coloresSemaforo, d.denominador, porcentaje);

            return {
              ...d,
              porcentaje: Number(porcentaje).toFixed(2),
              porcentajeHex
            };
          });

          dataProvincia.value = dataFormated;
          dataToPrint.provincia = dataFormated;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoadingProvincia.value = false));
    };

    const fetchDistrito = () => {
      const provincia = store.getters["pacientereporte/provincia"];

      isLoadingDistrito.value = true;
      PacienteReporteApi.getAllDistritoGestanteAnemia(provincia, yearEvaluacion.value)
        .then(async (response) => {
          // console.log("getAllDistrito", response);
          const dataFormated = response.data.map((d) => {
            const porcentaje = calculaPorcentaje(d.numerador, d.denominador);
            const porcentajeHex = semaforoMapa(coloresSemaforo, d.denominador, porcentaje);

            return {
              ...d,
              porcentaje: Number(porcentaje).toFixed(2),
              porcentajeHex
            };
          });

          dataDistrito.value = dataFormated;
          dataToPrint.distrito = dataFormated;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoadingDistrito.value = false));
    };

    const fetchEstablecimiento = () => {
      const provincia = store.getters["pacientereporte/provincia"];
      const distrito = store.getters["pacientereporte/distrito"];

      isLoadingEstablecimiento.value = true;
      PacienteReporteApi.getAllEstablecimientoGestanteAnemia(
        provincia,
        distrito,
        yearEvaluacion.value
      )
        .then(async (response) => {
          // console.log("getAllEstablecimiento", response);
          const dataFormated = response.data.map((d) => {
            const porcentaje = calculaPorcentaje(d.numerador, d.denominador);
            const porcentajeHex = semaforoMapa(coloresSemaforo, d.denominador, porcentaje);

            return {
              ...d,
              porcentaje: Number(porcentaje).toFixed(2),
              porcentajeHex
            };
          });

          dataCentroEstablecimiento.value = dataFormated;
          dataToPrint.establecimiento = dataFormated;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoadingEstablecimiento.value = false));
    };

    const fetchFechaImportacion = () => {
      UsuariosApi.getFechaImportacion("sien_anemia_new")
        .then((response) => {
          console.log("response", response);
          fechaIngreso.value =
            moment(response.data[0]?.fechaingreso).format("DD/MM/YYYY") || "No registrado";
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getEscalaColoresMapa = () => {
      MapaApi.getSemaforoMapa()
        .then((res) => {
          coloresSemaforo.PORCENTAJE_BAJO = res.data.find(
            (p) => p.denominacion === "MAPA_PORCENTAJE_BAJO"
          ).valor;
          coloresSemaforo.PORCENTAJE_MEDIO = res.data.find(
            (p) => p.denominacion === "MAPA_PORCENTAJE_MEDIO"
          ).valor;
          coloresSemaforo.PORCENTAJE_ALTO = res.data.find(
            (p) => p.denominacion === "MAPA_PORCENTAJE_ALTO"
          ).valor;

          escalaColores.value = generaLeyendaSemaforoPorcentaje(toRaw(coloresSemaforo));
        })
        .catch((err) => {
          console.log(err);
        });
    };

    watch(store.state.pacientereporte, () => {
      dataToPrint.provinciaFiltrado = store.state.pacientereporte.provincia;
      dataToPrint.distritoFiltrado = store.state.pacientereporte.distrito;
    });

    onMounted(() => {
      UsuariosApi.userLogAdd("Nutricional", "Tabla Niños Anemia", establecimiento);

      var anio = moment(new Date()).local().format("YYYY");

      var oldanios = [];
      for (let index = 0; index < 2; index++) {
        oldanios.push(anio - index);
      }

      anios.value = oldanios;
      store.dispatch("pacientereporte/cleanFilters");
      getEscalaColoresMapa();
      fetchProvincia();
      fetchDistrito();
      fetchEstablecimiento();
      fetchFechaImportacion();
    });

    const resetAllFetch = () => {
      store.dispatch("pacientereporte/cleanFilters");
      fetchProvincia();
      fetchDistrito();
      fetchEstablecimiento();
    };

    const customRow = (record) => {
      return {
        onClick: () => {
          store.dispatch("pacientereporte/setprovincia", record.nombre);
          store.dispatch("pacientereporte/setTipoProvincia", record.info);
          clearSelectedArrays();
          fetchDistrito();
        }
      };
    };

    const customRow2 = (record) => {
      return {
        onClick: () => {
          store.dispatch("pacientereporte/setdistrito", record.nombre);
          store.dispatch("pacientereporte/setTipoDistrito", record.info);
          clearSelectedArrays2();
          fetchEstablecimiento();
        }
      };
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
      state.rowActiveStatus = [];
    };

    const clearSelectedArrays2 = () => {
      state.selectedRowKeys2 = [];
      state.rowActiveStatus2 = [];
    };

    const handleProvincia = () => {
      ApiIndicador.getAllProvinciaNutricionGestante(yearEvaluacion.value)
        .then((response) => {
          window.open(
            process.env.VUE_APP_BASE_API + "/CSV/" + response.data["nombre_Archivo"],
            "_blank"
          );
        })
        .catch((err) => console.log(err));
    };

    const handleDistrito = () => {
      const provincia = store.getters["pacientereporte/provincia"];

      ApiIndicador.getAllDistritoNutricionGestante(provincia, yearEvaluacion.value)
        .then((response) => {
          window.open(
            process.env.VUE_APP_BASE_API + "/CSV/" + response.data["nombre_Archivo"],
            "_blank"
          );
        })
        .catch((err) => console.log(err));
    };

    const handleEstablecimiento = () => {
      const provincia = store.getters["pacientereporte/provincia"];
      const distrito = store.getters["pacientereporte/distrito"];

      ApiIndicador.getAllEstablecimientoNutricionGestante(provincia, distrito, yearEvaluacion.value)
        .then((response) => {
          window.open(
            process.env.VUE_APP_BASE_API + "/CSV/" + response.data["nombre_Archivo"],
            "_blank"
          );
        })
        .catch((err) => console.log(err));
    };

    const onSelectPeriodo = () => {
      store.dispatch("pacientereporte/cleanFilters");
      fetchProvincia();
      fetchDistrito();
      fetchEstablecimiento();
    };

    const formateaDataDistrito = computed(() => {
      return dataDistrito.value.map((data) => {
        const porcentaje = calculaPorcentaje(data.numerador, data.denominador);

        return {
          numeradorSobreDenominador: data.numerador + " / " + data.denominador,
          porcentaje,
          numerador: data.numerador,
          denominador: data.denominador,
          nombre: data.info
        };
      });
    });

    const formateaEstablecimiento = computed(() => {
      return dataCentroEstablecimiento.value.map((data) => {
        const porcentaje = calculaPorcentaje(data.numerador, data.denominador);

        return {
          numeradorSobreDenominador: data.numerador + " / " + data.denominador,
          porcentaje,
          numerador: data.numerador,
          denominador: data.denominador,
          nombre: data.info
        };
      });
    });

    const filterToPrint = (areaGeografica) => {
      tituloDeFiltro.value = areaGeografica;
      // console.log(tituloDeFiltro.value);
      const areaDesordenada = dataToPrint[areaGeografica];
      arraySelectPrint.value = areaDesordenada.sort((a, b) => {
        return Number(b.porcentaje) - Number(a.porcentaje);
      });
    };

    const filterToPrintEstablecimiento = (areaGeografica) => {
      tituloDeFiltro.value = areaGeografica;
      // console.log(tituloDeFiltro.value);
      const areaDesordenada = dataToPrint[areaGeografica];
      arraySelectPrint.value = areaDesordenada.sort((a, b) => {
        return a.microred.localeCompare(b.microred);
      });
    };

    const onSelectYear = () => {
      fetchProvincia();
      fetchDistrito();
      fetchEstablecimiento();
    };

    return {
      filterToPrint,
      onSelectYear,
      filterToPrintEstablecimiento,
      formateaDataDistrito,
      formateaEstablecimiento,
      handleProvincia,
      printObj,
      anios,
      handleDistrito,
      handleEstablecimiento,
      customRow,
      customRow2,
      dataProvincia,
      dataDistrito,
      dataCentroEstablecimiento,
      columnsProvincia,
      columnsDistrito,
      formState,
      columnsEstablecimiento,
      isLoadingProvincia,
      isLoadingDistrito,
      isLoadingEstablecimiento,
      resetAllFetch,
      onSelectPeriodo,
      arraySelectPrint,
      tituloDeFiltro,
      fechaIngreso,
      dataToPrint,
      printObjEstablecimiento,
      escalaColores,
      printObjTodo,
      years,
      yearEvaluacion
    };
  }
};
</script>
